.header-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 150px;
  text-align: center;
}

.value-box {
  font-size: 16px;
  font-weight: bold;
}

.word-break {
  word-break: break-all;
}
