.container {
  .btn-box {
    display: flex;
    align-items: center;
  }
  img {
    width: 20px;
    margin-right: 10px;
  }
}
