.container {
  background-color: #7b8aa8;
  min-height: 100%;
  padding-bottom: 30px;
}

.bridge-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  gap: 20px;
  margin-bottom: 30px;
}
.box-style {
  background-color: #262450;
  border-radius: 16px;
  border-top: 1px solid #393677;
}

.bridge-main {
  @extend .box-style;
  width: 800px;
  margin: 20px auto 0;
  padding: 30px;
}

.network-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .from-box {
    color: #fff;
    width: 45%;
  }
  .network-info {
    padding: 18px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #17153b;
    border-radius: 8px;
    .logo-box {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
    }
    img {
      width: 30px;
    }
    .network-label {
      margin-left: 15px;
      font-size: 20px;
    }
  }
  .to-box {
    color: #fff;
    width: 45%;
  }
  .switch-box {
    padding-top: 30px;
    :global {
      .ant-btn {
        background-color: #4bd3fc;
        color: #fff;
        border: 0;
      }
    }
  }
  .box-label {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    height: 30px;
  }
}
.account-box {
  margin-top: 30px;

  .box-title {
    color: #fff;
    margin-bottom: 20px;
  }
  .input-box {
    :global {
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #a0a3bd;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #a0a3bd;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #a0a3bd;
      }
      .ant-select-selector {
        height: 60px;
        background-color: #17153b;
        border: 0;
        color: #fff;
        padding: 0 30px;
        font-size: 14px;
      }
      .ant-select-selection-search-input {
        height: 100% !important;
      }
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        font-size: 16px;
      }
    }
  }
}

.amount-box {
  margin-top: 30px;

  .box-title {
    color: #fff;
    margin-bottom: 20px;
  }
  .input-box {
    :global {
      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #a0a3bd;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #a0a3bd;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #a0a3bd;
      }
      .ant-input-affix-wrapper {
        background-color: #17153b;
        border: 0;
      }
      .ant-input {
        height: 60px;
        background-color: #17153b;
        border: 0;
        color: #fff;
        padding: 0 20px;
        font-size: 20px;
      }
    }
  }
}

.burn-rate-box {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  .box-title {
    color: #fff;
    margin-right: 20px;
  }
  .value-box {
    text-align: right;
    color: #fff;
    font-size: 20px;
    width: 150px;
  }
  .input-box-show {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .input-box {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.fee-box {
  margin-top: 30px;
  padding: 10px 30px;
  border: 1px solid #3d3a80;
  border-radius: 15px;
  color: #999;
  padding: 20px;
  margin-bottom: 30px;
  text-align: center;
  .fee-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    .label-box {
      color: #a0a3bd;
    }
    .value-box {
      color: #fff;
    }
  }
}

.power-box {
  border: 1px solid #ddd;
  border-radius: 50px;
  width: 300px;
  padding: 15px 0;
  color: #a0a3bd;
  margin: 30px auto;
  text-align: center;
  font-size: 14px;
}

.transfer-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  :global {
    .ant-btn {
      width: 400px;
      font-size: 18px;
      height: 50px;
      background: linear-gradient(99.5deg, #53f6f8 0%, #4ac8fe 100%);
      border: #53f6f8;
      color: #fff;
      &:hover {
        color: #fff;
        background: linear-gradient(99.5deg, #95fbfd 0%, #91d9f8 100%);
      }
      &:active {
        background: linear-gradient(99.5deg, #18f4f8 0%, #2cc0ff 100%);
      }
    }
  }
}

.rule-box {
  text-align: left;
  font-size: 12px;
  color: #ddd;
  line-height: 20px;
  h3 {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
}

.data-container {
  max-width: 1200px;
  min-width: 800px;
  background-color: #fff;
  margin: 20px auto;
  padding: 10px 0;
  border-radius: 20px;
}
.data-box {
  padding: 0 10px;
  min-height: 500px;
}
