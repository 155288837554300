.header {
  padding: 0 30px;
  height: 60px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.5);
}
.logo-box {
  width: 50px;
  img {
    width: 100%;
  }
}

.nav-box {
  display: flex;
  align-items: center;
  height: 100%;
}

.nav-list {
  display: flex;
  margin-right: 30px;
  .nav-item {
    border-right: 1px solid #666;
    a {
      display: block;
      color: #333;
      width: 100%;
      text-decoration: none;
      padding: 0 30px;
      &:hover {
        color: #000;
      }
    }
  }
}

.pc-nav-box {
  display: block;
}
.mobile-btn {
  display: none;
}

@media screen and (max-width: 1000px) {
  .pc-nav-box {
    display: none;
  }
  .mobile-btn {
    display: block;
  }
  .nav-box {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 30px;
  }
  .nav-list {
    flex-direction: column;
    gap: 30px;
    .nav-item {
      border: none;
    }
  }
}
